import { useRouter } from "next/router";
import React from "react";
import Image from "@/components/global/Image";
import Link from "next/link";
import { handleLinkByType } from "@/helpers/common/handleLinkByType";

const MainSliderCard = ({ slider }) => {
  const { locale } = useRouter();
  const imgSrc = `${locale === "ar" ? slider?.["img-ar"] : slider?.["img-en"]}`;

  return (
    <div className="relative">
      <Image
        src={imgSrc}
        className="object-cover w-full h-80 sm:h-[697px]"
        loading="eager"
        alt=""
      />
      <div className="absolute top-0 left-0 z-50 w-full h-full bg-black bg-opacity-60">
        <div className="text-white text-lg sm:text-xl flex flex-col gap-2 sm:gap-4 justify-center h-full ms-5 sm:ms-10">
          <span>
            {locale === "ar"
              ? slider?.["subtitle-ar"]
              : slider?.["subtitle-en"]}
          </span>
          <h1 className="text-xl sm:text-3xl font-bold text-primary">
            {locale === "ar" ? slider?.["title-ar"] : slider?.["title-en"]}
          </h1>
          <span>
            {locale === "ar" ? slider?.["desc-ar"] : slider?.["desc-en"]}
          </span>
          <div className="flex gap-3 items-center text-sm text-white font-semibold">
            {slider?.buttons?.at(0) && (
              <Link
                href={handleLinkByType(
                  slider?.buttons?.at(0)?.url,
                  slider?.buttons?.at(0)?.type
                )}
                className=" bg-primary py-2 px-9"
              >
                {locale === "ar"
                  ? slider?.buttons?.at(0)?.["title-ar"]
                  : slider?.buttons?.at(0)?.["title-en"]}
              </Link>
            )}
            {slider?.buttons?.at(1) && (
              <Link
                href={handleLinkByType(
                  slider?.buttons?.at(1)?.url,
                  slider?.buttons?.at(1)?.type
                )}
                className="border border-primary py-2 px-9"
              >
                {locale === "ar"
                  ? slider?.buttons?.at(1)?.["title-ar"]
                  : slider?.buttons?.at(1)?.["title-en"]}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSliderCard;
